<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div class="cell">
            <el-button type="primary" @click="syncRule">同步防骚规则</el-button>
            <div>
              <div style="margin-left:5px">1)最新同步时间:{{time}}，规则明细请前往企微后台防骚扰模块进行查看</div>
              <div style="margin-left:5px;color:red">2)默认规则只能开启一条，新开通的群会直接使用默认规则</div>
            </div>
          </div>
          
        </el-space>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
        <el-table :data="tableData" style="width: 100%" border>
            <el-table-column prop="IndexID" label="规则编号"></el-table-column>
            <el-table-column prop="RuleName" label="规则名称"></el-table-column>
            <el-table-column prop="Type" label="类型">
              <template #default="scope">
                <div>{{scope.row.Type==0?"企业创建":"个人创建"}}</div>
              </template>
            </el-table-column>
            <el-table-column label="默认规则">
              <template #default="scope">
                <div>
                  <el-switch v-model="scope.row.IsDefault" active-color="#13ce66" @change="changeSwitch(scope.row,scope.$index)"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="关联群数">
              <template #default="scope">
                <div>{{scope.row.GroupList.length}}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" @click="settingAction(scope.row,scope.$index)">设置</el-button>
                </template>
            </el-table-column>
        </el-table>
        <TableFoot :pagination="pageObj" @changePage="changePage" @changeSize="changeSize"/>
    </div>
    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
  </div>
</template>
<script>
import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import Tool from '@/assets/js/tools'
import GroupSelectCop from "@/components/GroupSelectCop";
import {SyncAntiHarassmentRules,GetAntiHarassmentRuleList,SetAntiHarassmentRule,GroupSetDefaultAntiHarassmentRule} from '@/helper/group'

export default {
  name: "HarassRule",
  components: {
    TableFoot,GroupSelectCop
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const time=ref("");
    const groupSelectDialog=ref(null)
    onMounted(()=>{
        searchAction()
    })

    /******************************搜索**********************************/
    function searchAction(){
      let data = {
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize
      }
      GetAntiHarassmentRuleList(data).then(res=>{
        console.log(res);
        tableData.value = res.List || []
        for(let item of tableData.value){
          item.IsDefault = item.IsDefault == 0 ? false:true
        }
        time.value = res.LastSyncTime
        pageObj.TotalRow = res.TotalCount
      })
    }

    //同步
    function syncRule(){
        SyncAntiHarassmentRules({}).then(res=>{
          ElMessage.success("同步成功")
  
          searchAction()
        })
    }
    
    let holderIndex = 0
    //设置
    function settingAction(item,index) {
        holderIndex = index
        groupSelectDialog.value.initCop(0,item.GroupList,true,item)
    }

    //确认
    function chooseGroupAction(arr) {
      let item  = tableData.value[holderIndex]
      let data ={
        RuleSerialNo:item.RuleSerialNo,
        GroupIDs:arr.map(item=>item.GroupID)
      }
        SetAntiHarassmentRule(data).then(res=>{
          ElMessage.success("设置成功")
          searchAction()
        })
    }

    //switch改变
    function changeSwitch(row,index){
      let item = tableData.value[index]
      let str = "关闭后将没有默认规则，新开通的群将不会自动套用防骚扰规则，您确定要继续关闭吗？"
      if(row.IsDefault){
        str = '确定要切换防骚扰规则吗？切换后新开通的群将自动套用该防骚扰规则'
      }
      ElMessageBox.confirm(str, '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let params={
          Type:item.IsDefault ? 1 : 0
        }
        if(item.IsDefault){
          params.RuleSerialNo = item.RuleSerialNo 
        }
        GroupSetDefaultAntiHarassmentRule(params).then(res=>{
          ElMessage({
            type: 'success',
            message: '设置成功!',
          });
          searchAction()
        })
      }).catch(() => {
          item.IsDefault = !item.IsDefault
          ElMessage({
            type: 'info',
            message: '已取消',
          });
      });
    
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    return {
        tableData,
        pageObj,
        time,
        changePage,
        syncRule,
        changeSize,
        searchAction,
        settingAction,
        groupSelectDialog,
        chooseGroupAction,
        changeSwitch
    };
  },
};
</script>
<style lang="scss" scoped>
:deep().table-search span{
    margin-right: 0;
}
.cell-wrap{
    color:$color-common;
}
.cell{
  display: flex;
  align-items: center;
  font-size: 14px;
}
</style>